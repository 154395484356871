<template>
  <b-row>

    <h2 class="pl-1">FAQ Категории</h2>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between col-12">
      <b-col
        md="4"
        class="my-1 px-0"
      >
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Искать"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Очистить
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!--   CREATE   -->
      <div
        class="my-1 float-right"
      >
        <modal-button
          ripple="rgba(113, 102, 240, 0.15)"
          size="lg"
          name="modal-main"
          modal-title="Создание"
          modalButton="Создать"
          @btn-emit="createFaqCategory"
          @open-emit="clearInputs"
          variant="success"
          ref="create-modal-button"
        >
          <template v-slot:button @click="createFaqCategory">Создать</template>

          <template v-slot:modal-body>
            <ValidationObserver ref="validation-observer">
              <div class="d-flex flex-wrap">

                <!--   TITLE [RU]   -->
                <b-col cols="12">
                  <ValidationProvider :name="`Название [RU]`" rules="required" v-slot="{errors}">
                    <b-form-group
                      label="Название [RU]"
                      label-for="title_ru"
                    >
                      <b-form-input
                        v-model="title.ru"
                        id="title_ru"
                        size="lg"
                        placeholder="Название [RU]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

                <!--   TITLE [UZ]   -->
                <b-col cols="12">
                  <ValidationProvider :name="`Название [UZ]`" rules="required" v-slot="{errors}">
                    <b-form-group
                      label="Название [UZ]"
                      label-for="title_uz"
                    >
                      <b-form-input
                        v-model="title.uz"
                        id="title_uz"
                        size="lg"
                        placeholder="Название [UZ]"
                      />
                    </b-form-group>
                    <span class="validation__red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>

              </div>
            </ValidationObserver>
          </template>
        </modal-button>

      </div>
    </div>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        sort-icon-left
        :busy="isBusy"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">Идет загрузка ...</strong>
          </div>
        </template>

        <template #cell(title)="data">
          {{ data.item.title.ru }}
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">

            <!--    EDIT    -->
            <modal-button
              class="crud__btn"
              ripple="rgba(113, 102, 240, 0.15)"
              size="lg"
              @btn-emit="updateFaqCategory(data.item.id, data.item)"
              @open-emit="getOneFaqCategory(data.item.id)"
              :name="`modal-edit-${data.item.id}`"
              modalButton="Сохранить"
              :id="data.item.id"
              modal-title="Редактировать"
              variant="flat-warning"
              ref="update-modal-button"
            >
              <template
                v-slot:button
              >
                <feather-icon
                  icon="Edit2Icon"
                  size="21"
                />
              </template>

              <template v-slot:modal-body>
                <ValidationObserver ref="validation-observer">
                  <div class="d-flex flex-wrap">

                    <!--   TITLE [RU]   -->
                    <b-col cols="12">
                      <ValidationProvider :name="`Название [RU]`" rules="required" v-slot="{errors}">
                        <b-form-group
                          label="Название [RU]"
                          label-for="title_ru"
                        >
                          <b-form-input
                            v-model="title.ru"
                            id="title_ru"
                            size="lg"
                            placeholder="Название [RU]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                    <!--   TITLE [UZ]   -->
                    <b-col cols="12">
                      <ValidationProvider :name="`Название [UZ]`" rules="required" v-slot="{errors}">
                        <b-form-group
                          label="Название [UZ]"
                          label-for="title_uz"
                        >
                          <b-form-input
                            v-model="title.uz"
                            id="title_uz"
                            size="lg"
                            placeholder="Название [UZ]"
                          />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>

                  </div>
                </ValidationObserver>
              </template>

            </modal-button>

            <!--   DELETE   -->
            <modal-button
              class="crud__btn"
              ripple="rgba(113, 102, 240, 0.15)"
              size="sm"
              :name="`modal-delete-${data.item.id}`"
              modalButton="Удалить"
              modal-title="Удалить"
              variant="flat-danger"
              :id="data.item.id"
              ref="delete-modal"
              @btn-emit="deleteFaqCategory"
            >
              <template
                v-slot:button
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="21"
                />
              </template>

              <template v-slot:modal-body>
                <div class="my-2">
                  Вы действительно хотите удалить эту FAQ категорию?
                </div>
              </template>

            </modal-button>

          </div>
        </template>
      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
      cols="12"
    >
      <b-pagination
        v-if="showPagination"
        v-model="pagination.current"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>

  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BSpinner,
  BCard,
  BFormTextarea,
} from 'bootstrap-vue'
import ModalButton from "@/views/ui/modals/ModalButton";
import api from '@/services/api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "FaqCategories",
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
    ModalButton,
    ToastificationContent
  },
  directives: {
    Ripple
  },
  data() {
    return {
      title: {
        en: '',
        ru: '',
        uz: '',
      },
      position: null,
      isBusy: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'title',
          label: 'Заголовок',
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      items: [],
      pagination: {
        current: 1,
        total: 5,
        per_page: 5
      }
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        // const routeQueryPage = parseInt(query.page)
        this.pagination.current = query.page
      },
      deep: true,
      immediate: true
    },
    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    }
  },

  async created() {
    await this.getFaqCategories()
  },

  computed: {
    rows() {
      return this.items.length
    },

    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },

  methods: {

    showToast(variant, text, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: icon,
          variant,
        },
      })
    },

    replaceRouter(query) {
      this.$router.replace({query})
        .catch(() => {
        })
    },

    clearInputs() {
      this.title.en = ''
      this.title.ru = ''
      this.title.uz = ''
      this.position = ''
    },

    async getFaqCategories() {
      this.isBusy = true
      await api.faqCategories.fetchFaqCategories()
        .then(res => {
          this.items = res.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    async createFaqCategory() {
      const isValid = await this.$refs['validation-observer'].validate()
      if (isValid) {

        const formData = new FormData()
        formData.append('title[en]', this.title.en)
        formData.append('title[ru]', this.title.ru)
        formData.append('title[uz]', this.title.uz)
        formData.append('position', this.position)

        api.faqCategories.createNewFaqCategory(formData)
          .then(res => {
            this.$refs['create-modal-button'].closeModal()
            this.showToast('success', 'Успешно создано!', 'CheckIcon')
          })
          .catch((error) => {
            console.error(error)
            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
          })
          .finally(() => {
            this.getFaqCategories()
            this.clearInputs()
          })
      }
    },

    async getOneFaqCategory(id) {
      await api.faqCategories.fetchOneFaqCategory(id)
        .then(res => {
          this.title.en = res.data.title.en
          this.title.ru = res.data.title.ru
          this.title.uz = res.data.title.uz
          this.position = res.data.position
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
        })
    },

    async updateFaqCategory(id) {
      const isValid = await this.$refs['validation-observer'].validate()
      if (isValid) {
        const formData = new FormData()
        formData.append('title[en]', this.title.en)
        formData.append('title[ru]', this.title.ru)
        formData.append('title[uz]', this.title.uz)
        formData.append('position', this.position)

        api.faqCategories.updateFaqCategory(id, formData)
          .then(res => {
            this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
          })
          .catch((error) => {
            console.error(error)
            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
          })
          .finally(() => {
            this.$refs['update-modal-button'].closeModal()
            this.getFaqCategories()
            this.clearInputs()
          })
      }
    },

    deleteFaqCategory(id) {
      api.faqCategories.deleteFaqCategory(id)
        .then(res => {
          this.$refs["delete-modal"].closeModal()
          this.showToast('success', 'Успешно удалено!', 'CheckIcon')
        })
        .catch((error) => {
          console.error(error)
          this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
        })
        .finally(() => {
          this.getFaqCategories()
        })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>

[dir] .dropdown-item {
  padding: 0;
}

.validation__red {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 6px;
}

.crud__btn .btn {
  padding: 0.6rem;
}

</style>
